export const enTranslation = {
  common: {
    buttons: {
      back: 'Back',
      cancel: 'Cancel',
      confirm: 'Confirm',
      continue: 'Continue',
      delete: 'Delete',
      edit: 'Edit',
      next: 'Next',
      yes: 'Yes',
      submit: 'Submit',
    },
    labels: {
      email: 'E-mail',
      password: 'Password',
    },
    languages: {
      en: 'EN',
    },
    validations: {
      required: 'Field is required',
    },
  },
};
