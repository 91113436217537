import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslocoDirective } from '@ngneat/transloco';
import { ChrComponentsModule } from '@ciphr-design-system/angular';

@Component({
  selector: 'ciphr-no-records',
  standalone: true,
  imports: [TranslocoDirective, ChrComponentsModule],
  templateUrl: './no-records.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoRecordsComponent {}
