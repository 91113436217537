import { InjectionToken } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { AppConfig } from './app-config.type';

export const APP_CONFIG = new InjectionToken<BehaviorSubject<AppConfig | null>>('App Config', {
  factory: () => new BehaviorSubject<AppConfig | null>(null),
  providedIn: 'root',
});
