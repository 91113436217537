import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppNavigationComponent } from '@ciphr/core/app-navigation/features';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { TranslocoDirective } from '@jsverse/transloco';
import { selectSensitiveDataVisibility, sensitiveDataToggleActions } from '@ciphr/shared/sensitive-data-toggle/state';

@Component({
  selector: 'mypay-navigation',
  standalone: true,
  imports: [AppNavigationComponent, ChrComponentsModule, TranslocoDirective],
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  private store = inject(Store);

  selectSensitiveDataVisibility = this.store.selectSignal(selectSensitiveDataVisibility);

  togglePrivacyMode(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(sensitiveDataToggleActions.syncSensitiveData());
  }
}
