import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { UserSessionService } from './user-session.service';

import { TranslocoDirective } from '@jsverse/transloco';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ciphr-user-session',
  standalone: true,
  imports: [ChrComponentsModule, TranslocoDirective, DatePipe],
  templateUrl: './user-session.component.html',
  styleUrl: './user-session.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSessionComponent {
  private readonly userSessionService = inject(UserSessionService);

  remainingTime = this.userSessionService.remainingTime;
  refreshSession(): void {
    this.userSessionService.refreshSession();
  }

  logout(): void {
    this.userSessionService.logout();
  }
}
