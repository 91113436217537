import { Route } from '@angular/router';

import {
  PersonalDetailsApiDataAdapterService,
  providePersonalDetailsApiDataAdapter,
} from '@ciphr/domains/personal-details/api-data-adapter';

import { PayslipsApiDataAdapterService, providePayslipsApiDataAdapterProvider } from '@ciphr/domains/payslips/api-data-adapter';
import { providePayslipsState } from '@ciphr/domains/payslips/state';
import { provideTaxDocsApiDataAdapter, TaxDocsApiDataAdapterService } from '@ciphr/domains/tax-docs/api-data-adapter';
import { provideTaxDocsState } from '@ciphr/domains/tax-docs/state';
import { providePersonalDetailsState } from '@ciphr/domains/personal-details/state';
import { providePayrollApprovalState } from '@ciphr/domains/payroll-approval/state';
import {
  PayrollApprovalApiDataAdapterService,
  providePayrollApprovalApiDataAdapter,
} from '@ciphr/domains/payroll-approval/api-data-adapter';

export const appRoutes: Route[] = [
  {
    path: 'payslips',
    loadComponent: () => import('./payslips/payslips.component').then((c) => c.PayslipsComponent),
    providers: [providePayslipsApiDataAdapterProvider(), providePayslipsState(PayslipsApiDataAdapterService)],
  },
  {
    path: 'tax-docs',
    loadChildren: () => import('./tax-docs/tax-docs.routes').then((r) => r.taxDocsRoutes),
    providers: [provideTaxDocsApiDataAdapter(), provideTaxDocsState(TaxDocsApiDataAdapterService)],
  },
  {
    path: 'personal-details',
    loadComponent: () => import('./personal-details/personal-details.component').then((c) => c.PersonalDetailsPageComponent),
    providers: [providePersonalDetailsApiDataAdapter(), providePersonalDetailsState(PersonalDetailsApiDataAdapterService)],
  },
  {
    path: 'payroll-approval',
    loadChildren: () => import('./payroll-approval/payroll-approval.routes').then((r) => r.payrollApprovalRoutes),
    providers: [providePayrollApprovalApiDataAdapter(), providePayrollApprovalState(PayrollApprovalApiDataAdapterService)],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'payslips',
  },
];
