import { EnvironmentProviders, isDevMode, makeEnvironmentProviders } from '@angular/core';

import { provideTransloco } from '@ngneat/transloco';

import { commonTranslationConfig } from './common-translations/common-translation.config';
import { StaticTranslationLoader } from './static-translation.loader';
import { TRANSLATION_CONFIGS } from './translations-configs.token';
import { TranslationConfig } from './translation-config.type';

export const provideStaticTranslation = (appTranslationConfig: TranslationConfig): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideTransloco({
      config: { availableLangs: ['en'], defaultLang: 'en', prodMode: !isDevMode(), reRenderOnLangChange: true },
      loader: StaticTranslationLoader,
    }),
    { provide: TRANSLATION_CONFIGS, useValue: appTranslationConfig, multi: true },
    { provide: TRANSLATION_CONFIGS, useValue: commonTranslationConfig, multi: true },
  ]);
