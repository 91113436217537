import { APP_INITIALIZER, EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

import { appBrandingInitializerFactory } from './app-branding-initializer-factory';
import { AppBrandingService } from './app-branding.service';
import { HttpClient } from '@angular/common/http';

export const BRANDING_BACKGROUND_PHOTO = new InjectionToken<boolean>('Branding Background Photo');
export const BRANDING_API_CLIENT = new InjectionToken<HttpClient>('Branding Api Client Photo');

export const provideAppBranding = (httpApiClientToken: InjectionToken<HttpClient>, loadBackgroundPhoto = false): EnvironmentProviders =>
  makeEnvironmentProviders([
    { provide: BRANDING_BACKGROUND_PHOTO, useValue: loadBackgroundPhoto },
    { provide: BRANDING_API_CLIENT, useExisting: httpApiClientToken },
    { provide: APP_INITIALIZER, useFactory: appBrandingInitializerFactory, deps: [AppBrandingService], multi: true },
  ]);
