import { APP_INITIALIZER, EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';

import { AuthConfig, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';

import { authInitializerFactory } from './auth-initializer.factory';
import { authInterceptor } from './auth.interceptor';
import { provideHttpApiClient } from '@ciphr/utils/data-adapters';
import { AppPrefixAuthStorage } from './app-prefix-auth-storage';

export const SINGLE_SIGN_ON_HTTP_API_CLIENT = new InjectionToken<HttpClient>('Single Sign On Http API Client');

export const provideAuth = (config: AuthConfig, apiUrls: string[], applicationPrefix: string): EnvironmentProviders =>
  makeEnvironmentProviders([
    provideHttpClient(withInterceptors([authInterceptor(apiUrls)])),
    provideHttpApiClient(SINGLE_SIGN_ON_HTTP_API_CLIENT, { name: 'sso', version: 1 }, [authInterceptor(['/sso/api/v1'])]),
    provideOAuthClient(),
    { provide: AuthConfig, useValue: config },
    { provide: OAuthStorage, useFactory: () => new AppPrefixAuthStorage(applicationPrefix) },
    { provide: APP_INITIALIZER, useFactory: authInitializerFactory, multi: true },
  ]);
