import { OAuthStorage } from 'angular-oauth2-oidc';

export class AppPrefixAuthStorage extends OAuthStorage {
  constructor(private appName: string) {
    super();
  }
  getItem(key: string): string | null {
    return sessionStorage.getItem(`${this.appName}_${key}`);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(`${this.appName}_${key}`);
  }

  setItem(key: string, data: string): void {
    sessionStorage.setItem(`${this.appName}_${key}`, data);
  }
}
