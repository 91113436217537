import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

import { PendoOptions } from './pendo-options.type';

interface Pendo {
  clearSession: () => void;
  initialize: (options: PendoOptions) => void;
  pageLoad: (url?: string) => void;
  startSendingEvents: () => void;
  stopSendingEvents: () => void;
  track: (eventName: string, additionalParams?: Record<string, unknown>) => void;
}

const pendoFactory = (): Pendo | null => {
  const { defaultView } = inject(DOCUMENT);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pendo = defaultView ? ((defaultView as Record<string, any>)['pendo'] as Pendo) : null;

  if (!pendo) {
    console.error('Pendo has not been added to html head');
  }

  return pendo;
};

export const PENDO = new InjectionToken<Pendo | null>('Pendo', { factory: pendoFactory, providedIn: 'root' });
