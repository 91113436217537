import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthContext } from '@ciphr/core/auth-context/models';
import { AuthContextDataAdapter } from '@ciphr/core/auth-context/state';

import { AUTH_CONTEXT_HTTP_CLIENT } from './auth-context-http-client.token';

@Injectable()
export class AuthContextApiDataAdapterService implements AuthContextDataAdapter {
  private http = inject(AUTH_CONTEXT_HTTP_CLIENT);

  fetchAuthContextsList(): Observable<AuthContext[]> {
    return this.http.get<AuthContext[]>('/authorization/contexts');
  }
}
