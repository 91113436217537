import { inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { TRANSLATION_CONFIGS } from './translations-configs.token';

@Injectable({ providedIn: 'root' })
export class StaticTranslationLoader implements TranslocoLoader {
  private translationConfigs = inject(TRANSLATION_CONFIGS);

  getTranslation(lang: string): Observable<Translation> {
    return of(this.translationConfigs.reduce((translation, config) => ({ ...translation, ...config[lang] }), {}));
  }
}
