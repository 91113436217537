import { Injectable } from '@angular/core';
import { SensitiveDataToggleAdapter } from '@ciphr/shared/sensitive-data-toggle/state';

@Injectable()
export class SensitiveDataToggleLocalStorageAdapterService implements SensitiveDataToggleAdapter {
  loadSensitiveDataState(): boolean | null {
    const value = localStorage.getItem('sensitiveDataVisibility');

    return value !== null ? value === 'hidden' : null;
  }

  syncSensitiveDataState(visibility: boolean): void {
    localStorage.setItem('sensitiveDataVisibility', visibility ? 'visible' : 'hidden');
  }

  loadNotificationState(): boolean {
    return !!localStorage.getItem('sensitiveDataNotification');
  }

  dismissNotificationState() {
    localStorage.setItem('sensitiveDataNotification', 'dismissed');
  }
}
