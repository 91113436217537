import { TranslocoTestingModule } from '@jsverse/transloco';

import * as en from '../../../../../translations/translations.json'; // eslint-disable-line

export const translationTestingModule = TranslocoTestingModule.forRoot({
  langs: { en },
  translocoConfig: {
    availableLangs: ['en'],
    defaultLang: 'en',
  },
  preloadLangs: true,
});
