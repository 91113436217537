import { APP_INITIALIZER, EnvironmentProviders, FactorySansProvider, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserSessionService } from './user-session.service';
export const USER_SESSION_API_CLIENT = new InjectionToken<HttpClient>('User Session Api Client');

export const provideUserSession = (
  httpApiClientToken: InjectionToken<HttpClient>,
  factory: FactorySansProvider = { useFactory: ssoSessionFactory, deps: [UserSessionService] },
): EnvironmentProviders =>
  makeEnvironmentProviders([
    { provide: USER_SESSION_API_CLIENT, useExisting: httpApiClientToken },
    { provide: APP_INITIALIZER, ...factory, multi: true },
  ]);

const ssoSessionFactory = (userSessionService: UserSessionService) => () => userSessionService.fetchUserSessionScript().subscribe();
