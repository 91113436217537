import { ErrorHandler, inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { TranslocoService } from '@jsverse/transloco';

import { SnackBarService } from '@ciphr/ui';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  private readonly snackbarService = inject(SnackBarService);
  private readonly translocoService = inject(TranslocoService);

  handleError(error: unknown) {
    if (error instanceof HttpErrorResponse) {
      let errorMessage = 'COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE';
      const extractMessage = (err: any): string =>
        err.details.find((detail: { code: 'ERROR'; message: string }) => detail.code === 'ERROR')?.message || errorMessage;

      if (error.error instanceof Blob && (error.error.type === 'application/json' || error.error.type === 'application/problem+json')) {
        error.error.text().then((text) => this.snackbarService.open(extractMessage(JSON.parse(text)), 'error'));
        return;
      } else if ([HttpStatusCode.BadRequest, HttpStatusCode.NotFound].includes(error.status)) {
        errorMessage = extractMessage(error.error);
      }

      this.snackbarService.open(this.translocoService.translate(errorMessage), 'error');
    } else {
      console.error(error);
    }
  }
}
