import { HttpBackend, HttpClient, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { tap } from 'rxjs';

import { APP_CONFIG } from './app-config.token';
import { AppConfig } from './app-config.type';

export const appConfigInitializerFactory = () => {
  const appConfigSource = inject(APP_CONFIG);
  const httpBackend = inject(HttpBackend);

  const httpClient = new HttpClient({ handle: (req: HttpRequest<void>) => httpBackend.handle(req) });

  return () => httpClient.get<AppConfig>('assets/app-config.json').pipe(tap((config) => appConfigSource.next(config)));
};
