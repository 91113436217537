import { inject, Injectable, signal } from '@angular/core';

import { filter, tap } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';
import { PendoService } from '@ciphr/core/pendo';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly oauthService = inject(OAuthService);
  private readonly pendoService = inject(PendoService);

  readonly isSsoAdmin = signal(false);
  readonly issuer = this.oauthService.issuer;
  readonly username = signal('');

  private readonly claimsReady$ = this.oauthService.events.pipe(
    filter(({ type }) => type === 'discovery_document_loaded' || type === 'token_received'),
  );
  readonly loggedIn$ = this.oauthService.events.pipe(filter(({ type }) => type === 'token_received' || type === 'session_unchanged'));
  readonly tokenRefreshed$ = this.oauthService.events.pipe(filter((event) => event.type === 'token_refreshed'));

  constructor() {
    this.claimsReady$.subscribe(() => {
      const claims = this.oauthService.getIdentityClaims();

      claims && this.isSsoAdmin.set(claims['isSsoAdmin'] === 'True');
      claims && this.username.set(claims['preferred_username']);
    });

    this.oauthService.events
      .pipe(
        filter((e) => e.type === 'session_terminated'),
        tap(() => this.pendoService.clearSession()),
      )
      .subscribe(() => this.oauthService.initLoginFlow());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  logOut(): void {
    this.oauthService.logOut();
  }
}
