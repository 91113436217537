import { Directive, effect, inject, input } from '@angular/core';

import { catchError, EMPTY, map } from 'rxjs';

import { ChrAvatarDirective } from '@ciphr-design-system/angular';

import { HTTP_API_CLIENT } from '@ciphr/utils/data-adapters';

import { AVATARS_API_URL } from './avatars-api-url.token';
import { HttpClient } from '@angular/common/http';
import { SINGLE_SIGN_ON_HTTP_API_CLIENT } from '@ciphr/core/auth';

@Directive({
  selector: '[ciphrAvatar]',
  standalone: true,
})
export class AvatarDirective {
  private readonly avatarComponent = inject(ChrAvatarDirective, { self: true });
  private readonly avatarsApiUrl = inject(AVATARS_API_URL, { optional: true });
  private readonly httpClient = inject(HTTP_API_CLIENT);
  private readonly singleSignOnHttpClient = inject(SINGLE_SIGN_ON_HTTP_API_CLIENT);

  avatarPath = input<string | null | undefined>(undefined, { alias: 'ciphrAvatar' });

  constructor() {
    if (!this.avatarComponent) {
      throw "AvatarDirective must be used only on DS's chr-avatar component";
    }

    effect(() => {
      if (this.avatarPath()) {
        const apiUrl = (this.avatarsApiUrl || '/static/avatars/') + this.avatarPath();
        this.fetchAvatar(this.httpClient, apiUrl);
      } else {
        this.fetchAvatar(this.singleSignOnHttpClient, '/static/avatars');
      }
    });
  }

  private fetchAvatar(httpClient: HttpClient, apiUrl: string): void {
    httpClient
      .get(apiUrl, { responseType: 'blob' })
      .pipe(
        map((res) => URL.createObjectURL(res)),
        catchError(() => EMPTY),
      )
      .subscribe((objectUrl) => (this.avatarComponent.image = objectUrl));
  }
}
