import { inject, Injectable, signal } from '@angular/core';

import { filter } from 'rxjs';

import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private oauthService = inject(OAuthService);

  readonly isSsoAdmin = signal(false);
  readonly issuer = this.oauthService.issuer;
  readonly username = signal('');

  readonly discoveryDocumentLoaded$ = this.oauthService.events.pipe(filter(({ type }) => type === 'discovery_document_loaded' || type === 'token_received'));
  readonly tokenRefreshed$ = this.oauthService.events.pipe(filter((event) => event.type === 'token_refreshed'));

  constructor() {
    this.discoveryDocumentLoaded$.subscribe(() => {
      const claims = this.oauthService.getIdentityClaims();

      claims && this.isSsoAdmin.set(claims['isSsoAdmin'] === 'True');
      claims && this.username.set(claims['preferred_username']);
    })

    this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated')).subscribe(() => this.oauthService.initLoginFlow());
    this.oauthService.setupAutomaticSilentRefresh();
  }

  logOut(): void {
    this.oauthService.logOut();
  }
}
